@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    .card {
        @apply block p-3 sm:p-4 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10;
    }
}
html,
body {
    margin: 0;
    padding: 0;
}
body {
    @apply font-ar;
    font-size: 1.25rem;
    // font-family: 'Noto Sans', 'Helvetica Neue', serif;
    // <uniquifier>: Use a unique and descriptive class name
    // <weight>: Use a value from 100 to 900
    font-weight: 300;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}

[lang="ar"] {
    @apply font-ar;
    // font-family: 'Noto Sans Arabic', sans-serif !important;
}

* {
    box-sizing: border-box;
}

.html {
    display: block;
    h1 {
        @apply text-4xl font-bold mt-6 mb-3;
    }
    h2 {
        @apply text-2xl font-semibold mt-6 mb-3;
    }
    h3 {
        @apply text-xl font-semibold mt-6 mb-3;
    }
    h4 {
        @apply text-lg font-semibold mt-6 mb-3;
    }
    h5 {
        @apply text-base font-semibold mt-6 mb-3;
    }
    h6 {
        @apply text-sm font-semibold mt-6 mb-3;
    }

    ul > li {
        @apply list-disc list-inside;
        // list-style: none;
        // background: url(/assets/favicon-16x16.png) no-repeat center left;
        // padding: 0px 0 0px 24px;

        * {
            display: inline-block;
            margin-block: 0;
        }
    }
    ol > li {
        @apply list-decimal list-inside;
        * {
            display: inline-block;
        }
    }

    p {
        @apply text-gray-900 dark:text-gray-200 mb-4;
    }
    a {
        @apply text-green-600;
        @apply underline; // additional style for links
    }
    img {
        @apply w-full;
    }

    @media (max-width: 768px) {
        h1 {
            @apply text-3xl;
        }
        h2 {
            @apply text-2xl;
        }
        h3 {
            @apply text-xl;
        }
    }
}

[lang="ar"] {
    @apply font-ar;
    // font-family: 'Noto Sans Arabic', sans-serif !important;
    .html {
        li {
            // background: url(/assets/favicon-16x16.png) no-repeat center right;
            // padding: 0px 24px 0px 0;
        }
    }
}
